@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
:root {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Color vars//
  --bodyBg: #f1f5f9;
  --midGrayColor: #707070;
  --midGrayBorder: #7070703c;
  --offWhite: #f9f9fd;
  --black: #000000;
  --white: #ffffff;
  --error: #f93232;
  --success: #00b25d;
  --mainBg: #041410;
  --cardBg: #143936;
  --sidebarText: #64748b;
  --mainBlack: #1a202e;
  --greenbg: #cffe25;
  --borderColor: #c5d0e0;
  --text353535: #353535;
  --mainWhite: #fcfcfc;
  --acceptBg: #ecf8e3;
  --accepttext: #5cd100;
  --rejectBg: #fae7e7;
  --rejectText: #e32424;
  --textE0EAF4: #e0eaf4;

  // Typography //
  --largSize100: 100px;
  --largSize50: 50px;
  --largSize40: 40px;
  --headingOne: 32px;
  --heading30: 30px;
  --heading28: 28px;
  --headingTwo: 22px;
  --headingThree: 24px;
  --heading26: 26px;
  --headingFour: 20px;
  --largeParaGraph: 18px;
  --largeParaGraph16: 16px;
  --regularParagraph: 15px;
  --mediumParaGraph: 14px;
  --smallParagraph: 13px;
  --xtrasmallParagraph: 12px;

  @media (max-width: 1024px) {
    --largSize100: 80px;
    --largSize50: 40px;
    --largSize40: 32px;
    --headingOne: 28px;
    --heading30: 26px;
    --heading28: 24px;
    --headingTwo: 20px;
    --headingThree: 22px;
    --heading26: 22px;
    --headingFour: 18px;
    --largeParaGraph: 16px;
    --largeParaGraph16: 15px;
    --regularParagraph: 14px;
    --mediumParaGraph: 13px;
    --smallParagraph: 12px;
    --xtrasmallParagraph: 11px;
  }

  /* Small screens (mobile devices) */
  @media (max-width: 768px) {
    --largSize100: 60px;
    --largSize50: 30px;
    --largSize40: 24px;
    --headingOne: 24px;
    --heading30: 22px;
    --heading28: 20px;
    --headingTwo: 18px;
    --headingThree: 18px;
    --heading26: 18px;
    --headingFour: 16px;
    --largeParaGraph: 14px;
    --largeParaGraph16: 14px;
    --regularParagraph: 13px;
    --mediumParaGraph: 12px;
    --smallParagraph: 11px;
    --xtrasmallParagraph: 10px;
  }

  /* Extra small screens (very small mobile devices) */
  @media (max-width: 480px) {
    --largSize100: 50px;
    --largSize50: 25px;
    --largSize40: 20px;
    --headingOne: 20px;
    --heading30: 18px;
    --heading28: 16px;
    --headingTwo: 16px;
    --headingThree: 16px;
    --heading26: 16px;
    --headingFour: 14px;
    --largeParaGraph: 12px;
    --largeParaGraph16: 12px;
    --regularParagraph: 11px;
    --mediumParaGraph: 10px;
    --smallParagraph: 9px;
    --xtrasmallParagraph: 8px;
  }
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: 4px;
  height: 100vh;
  overflow: auto;
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.container {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
.ant-layout-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: auto;

  .flexAuto {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.commonParagraph {
  font-size: var(--largeParaGraph16);
  font-weight: 400px;
  color: var(--sidebarText);
}
.commonCardBG {
  background: var(--mainWhite);
  border-radius: 10px;
  padding: 24px !important;
  box-shadow: 0px 4px 12px 0px #00000021;
  @media(max-width:1399px){
    padding: 28px !important;
  }
  @media screen and (max-width: 590px) {
    padding: 15px !important;
  }
}
// .ant-row {
//   margin-left: 0px !important;
//   margin-right: 0px !important;
// }
///////////// Table //////////

.ant-table-wrapper {
  background: var(--mainWhite);
  box-shadow: 0px 4px 12px 0px #00000021;
  border-radius: 10px;
  overflow-x: auto;
}

.ant-table {
  min-width: 100%;
  width: 30px;
  .ant-table-thead {
    th {
      background: var(--sidebarText) !important;
      color: var(--mainWhite) !important;
      font-size: var(--largeParaGraph16);
      font-weight: 500;
      white-space: nowrap;
      padding: 20px 15px;
      &:first-child {
        border-top-left-radius: 10px !important;
      }
      &:last-child {
        border-top-right-radius: 10px !important;
      }
      &::before {
        position: unset !important;
      }
    }
  }
  .ant-table-tbody {
    td {
      color: var(--mainBlack);
      font-size: var(--largeParaGraph16);
      font-weight: 400;
      white-space: nowrap;
      padding: 21px 10px;
      border-bottom: 1px solid var(--borderColor);
    }
  }
}

.ant-pagination {
  display: none;
}

.ant-switch {
  border: unset;
  min-width: 32px;
  width: 32px;
  height: 18px;
  // background: var(--mainBg);
  &:hover {
    background: var(--mainBg) !important;
  }
  &-checked {
    background: var(--mainBg) !important;
    .ant-switch-handle {
      inset-inline-start: calc(100% - 17px) !important;
      inset-inline-start: calc(100% - 17px) !important;
      &::before {
        background-color: var(--greenbg) !important;
      }
    }
  }
}
.ant-switch-handle {
  width: 14px !important;
  height: 14px !important;
  color: var(--greenbg);
  &::before {
    background-color: var(--sidebarText) !important;
    border-radius: 50% !important;
  }
}
.actionBtn {
  display: flex;
  align-items: center;
  gap: 24px;
}
.status {
  display: flex;
  align-items: center;
  gap: 10px;
  .accept {
    background-color: var(--acceptBg);
    width: 99px;
    height: 36px;
    text-align: center;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: var(--accepttext);
      font-size: var(--largeParaGraph16);
      font-weight: 400;
    }
  }
  .reject {
    background-color: var(--rejectBg);
    width: 99px;
    height: 36px;
    text-align: center;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: var(--rejectText);
      font-size: var(--largeParaGraph16);
      font-weight: 400;
    }
  }
  .approved {
    span {
      color: var(--accepttext);
      font-size: var(--largeParaGraph16);
      font-weight: 400;
    }
  }
}

/////////Tabs///////////
:where(.css-dev-only-do-not-override-11lehqq).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover {
  color: var(--sidebarText) !important;
  border-color: var(--sidebarText) !important;
  background: #ffffff;
}
:where(.css-dev-only-do-not-override-qnu6hi).ant-btn-default {
  color: var(--sidebarText);
}
.ant-dropdown-menu-item {
  padding: 0 !important;
}
.ant-dropdown-menu-title-content {
  p {
    padding: 5px 12px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
.ant-tabs {
  .ant-tabs-nav {
    flex-flow: wrap;
    &::before {
      border: none !important;
    }
  }
  .ant-tabs-extra-content {
    margin-top: 10px;
  }
  .ant-tabs-tab {
    padding: 0;
    padding-bottom: 12px;
  }
  .ant-tabs-tab-btn {
    color: var(--sidebarText);
    font-size: var(--largeParaGraph16);
    font-weight: 400;
    &:active {
      color: var(--mainBlack) !important;
    }
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: var(--mainBlack) !important;
      font-weight: 600;
    }
  }
  .ant-tabs-ink-bar {
    background: var(--mainBlack);
  }
}
///////////// ant-picker ////////////////

.ant-picker {
  height: 40px;
  border-color: var(--sidebarText);
  border-radius: 10px;
  &:hover {
    border-color: var(--sidebarText);
  }
  .ant-picker-active-bar {
    display: none;
  }
  .ant-picker-active-bar {
    input {
      color: var(--sidebarText);
    }
  }
  .anticon-swap-right {
    svg {
      fill: var(--sidebarText);
    }
  }
}
.totalPayment {
  background: var(--greenbg);
  padding: 10px 13px;
  border-radius: 10px;
  p {
    color: var(--mainBlack);
    font-size: var(--largeParaGraph16);
    font-weight: 400;
    span {
      font-weight: 700;
    }
  }
}
.currencyPayout {
  max-width: 167px;
  width: 100%;
  p {
    background-color: var(--textE0EAF4);
    padding: 11px;
    border-radius: 10px;
    height: 40px;
    white-space: nowrap;
  }
}
.commonMonthBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 166px;
  width: 100%;
  justify-content: space-between;
  // @media screen and (max-width: 590px) {
  //   gap: 5px;
  //   max-width: 110px;
  // }
  button {
    background: none;
    border: none;
    font-size: var(--largeParaGraph16);
    font-weight: 400;
    color: var(--sidebarText);
    cursor: pointer;
    &:active,
    &:hover {
      color: var(--mainBg);
      font-weight: 500;
    }
  }
  .active {
    color: var(--mainBg);
    font-weight: 600;
  }
}
.commonBreadcrumb {
  margin-bottom: 20px;
  .ant-breadcrumb {
    .ant-breadcrumb-link {
      span {
        color: var(--sidebarText);
      }
    }
  }
}


.required-asterisk {
  color: red;
  margin-left: 2px;
}

.phone_input {
  input[type="tel"] {
    height: 45px;
    width: 100%;
    
    &:hover,
    &:focus,
    &:active {
      border: 1px solid var(--mainBlack);
    }
  }
}

.UserTable{
  .ant-pagination .ant-pagination-item-active{
    background-color: #000000;
    color: white;
    border-color: #000000;
    a{
      color: white;
    }
  }
  .ant-pagination-item{
    border-radius: 50%;
    overflow: hidden;
    background-color: #F1F5F9;
    color: #64748B;

   }
}

button.customDrop{
 border: unset;
 box-shadow: unset;
 padding: unset;
}