.footer {
  background-color: var(--mainWhite);
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
    font-size: var(--mediumParaGraph);
    font-weight: 400;
    color: var(--text353535);
  }
}
