.customDropdown {
  border-color: var(--sidebarText);
  height: 40px;
  border-radius: 10px;
  padding: 15px;
  max-width: 178px;
  width: 100%;
  &:hover,
  :active {
    border-color: var(--sidebarText) !important;
  }
  &:focus,
  :focus-visible {
    outline: none !important;
  }
  span {
    color: var(--sidebarText);
  }
}
.merchantDataList {
  background-color: var(--white);
  border-radius: 6px;
  ul {
    min-height: 300px;
    height: 300px;
    width: 300px;
    overflow: auto;
  }

  li {
    padding: 4px 10px !important;
  }
}
