.inputLayout {
  position: relative;

  .inputCustum {
    border: 1px solid var(--borderColor);
    border-radius: 5px;
    color: var(--mainBlack);
    width: 100%;
    min-height: 45px;
    box-shadow: none;
    position: relative !important;

    &:focus {
      box-shadow: none;
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      border: 1px solid var(--mainBlack);
      outline: none !important;
      box-shadow: none !important;
    }
  }

  input::placeholder {
    color: var(--sidebarText);
    font-size: var(--largeParaGraph16);
    font-weight: 400;
  }

  .inputInnerTxt {
    padding-right: 100px;
  }

  .eyeicon {
    // &:hover {
    //   @include theme() {
    //     border-color: theme-get("inputborder") !important;
    //   }
    // }
    input {
      background: transparent;

      // @include theme() {
      //   color: theme-get("whiteblackBase");
      // }
    }

    svg path {
      fill: #8d8d8d;
    }
  }

  .innerTxt {
    position: absolute;
    right: 16px;
    top: 55%;
    // transform: translateY(-50%);
    cursor: pointer;
  }

  .inputInnerLargeTxt {
    padding-right: 185px;
  }

  .innerTxtImg {
    position: absolute;
    right: 16px;
    top: 55%;
    // transform: translateY(-50%);
    color: #555863;
    display: flex;
    align-items: center;

    svg {
      margin-left: 10px;
    }
  }

  .inputSearch {
    padding-left: 45px;
    border: none !important;
    background: var(--bodyBg) !important;
    height: 40px !important;
    border-radius: 10px !important;
    @media screen and (max-width: 767px) {
      height: 30px !important;
      min-height: 30px !important;
    }
  }

  .searchImg {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: 767px) {
      svg {
        height: 15px;
        width: 15px;
      }
    }
  }

  .label {
    letter-spacing: 0px;
    color: var(--mainBlack);
    width: 100%;
    text-align: left;
    font-size: var(--largeParaGraph16);
    font-weight: 400;
    display: flex;
    padding: 10px 0;
  }

  .label1 {
    letter-spacing: 0px;
    color: var(--mainBlack);
    width: 100%;
    text-align: left;
    font-size: var(--mediumParaGraph);
    display: flex;
    margin-bottom: -10px;
  }

  .textedit {
    font-weight: 450;
  }

  .texteditLight {
    font-weight: 450;
  }
}
.phoneInputWrapper {
  .ant-select-selector {
    padding: 0 6px !important;
  }
  .ant-select-single {
    height: 45px;
    max-width: 60px;
    width: 100%;
  }
  &_select {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
.required-asterisk {
  color: red;
  margin-left: 2px;
}
