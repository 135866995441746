.currencyPreferences {
  h3 {
    font-size: var(--largeParaGraph);
    font-weight: 400;
    color: var(--mainBlack);
    margin-bottom: 10px;
    word-break: break-all;
  }
  p {
    font-size: var(--largeParaGraph16);
    font-weight: 500;
    color: var(--sidebarText);
  }
  &_head {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-flow: wrap;
    &_right {
      display: flex;
      align-items: center;
      gap: 30px;
      flex-flow: wrap;
      justify-content: flex-end;
      flex: 1;
    }

    h2 {
      font-size: var(--headingFour);
      font-weight: 500;
      color: var(--mainBlack);
      margin-bottom: 35px;
    }
  }
}
.depositCard {
  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  &_item {
    flex: 1 1 calc(33.3% - 24px);
    max-width: calc(33.3% - 24px);
    // max-width: 513px;
    width: 100%;
    @media (max-width: 1699px) {
      flex: 1 1 calc(50% - 24px);
      max-width: calc(50% - 24px);
    }
    @media (max-width: 991px) {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }

  &_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    h4 {
      font-size: var(--mediumParaGraph);
      font-weight: 400;
      color: var(--mainBlack);
    }
  }
  &_bottom {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;

    &_left {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}
.enabledPayout {
  margin-top: 36px;
  &_card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 24px;
    &_item {
      flex: 1 1 calc(33.3% - 24px);
      max-width: calc(33.3% - 24px);
      width: 100%;
      @media (max-width: 1699px) {
        flex: 1 1 calc(50% - 24px);
        max-width: calc(50% - 24px);
      }
      @media (max-width: 575px) {
        flex: 1 1 100%;
        max-width: 100%;
      }
    }
    &_left {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}
