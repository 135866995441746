.header {
  padding: 25px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fcfcfc;
  @media screen and (max-width: 767px) {
    align-items: flex-start;
    padding: 15px;
  }
  h3 {
    font-size: var(--headingFour);
    font-weight: 700;
    line-height: 23.44px;
    margin: 0;
    color: var(--mainBlack);
  }
  &_right {
    display: flex;
    align-items: center;
    justify-content: end;
    flex-flow: wrap-reverse;
    gap: 15px;
    &_logOut {
      display: flex;
      align-items: center;
      gap: 7px;
    }
  }
}
