.loginHeader {
  padding: 19px;
  border-bottom: 1px solid var(--borderColor);
  img {
    margin-left: 100px;
    @media screen and (max-width:1240px) {
      margin-left: 0;
    }
  }
}
.loginScreen {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  max-width: 1470px;
  width: 100%;
  h2 {
    opacity: 1;
    font-size: var(--heading30);
    margin: 0;
    margin-bottom: 28px;
  }
  &__leftCol {
    max-width: 483px;
    width: 100%;
    img {
      max-width: 483px;
      width: 100%;
    }
    h2 {
      font-size: var(--largSize50);
      font-weight: 700;
      color: var(--black);
      text-align: center;
    }
    @media (max-width: 767px) {
      display: none !important;
    }
  }
  &__rytCol {
    max-width: 720px;
    width: 100%;
    background: var(--bodyBg);
    padding: 36px 50px;
    border-radius: 20px;
    min-height: 0;
    @media (max-width: 767px) {
      width: 100%;
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }
  }
}
