.btnCustom.ant-btn {
  width: 100%;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-size: var(--largeParaGraph16);
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.25s;
  border: 0px;
  box-shadow: none;

  &:hover {
    opacity: 0.7;
  }

  &.lessPadding {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &.greenBtn {
    background-color: var(--greenbg);
    border: 1px solid transparent;

    &:hover {
      background: transparent;
      color: var(--mainBlack);
      border-color: var(--mainBlack);
      opacity: 1;
    }

    .fontFamilyText {
      color: var(--black);
    }
  }

  &.borderBtn {
    background: transparent;
    color: var(--mainBlack);
    border: 1px solid var(--mainBlack);

    &:hover {
      background: var(--greenbg);
      color: var(--mainBlack);
      border: 1px solid var(--mainBlack);
      opacity: 1;
    }
  }

  &.medium {
    height: 29px;
  }

  &.large {
    height: 45px;
  }
}
.downloadBtn {
  border: 1px solid var(--mainBlack);
  color: var(--mainBlack);
  padding: 12px 10px;
  height: 40px;
  border-radius: 10px;
}
.approveBtn {
  padding: 12px 10px;
  height: 40px;
  border-radius: 10px;
  border: none;
  background: var(--accepttext);
  color: var(--white);
  width: 100%;
  &:hover {
    background: var(--accepttext) !important;
    color: var(--white) !important;
  }
}
.disable {
  padding: 12px 10px;
  height: 40px;
  border-radius: 10px;
  border: none;
  background: var(--accepttext);
  color: var(--white);
  width: 100%;
}
.rejectBtn {
  padding: 12px 10px;
  height: 40px;
  border-radius: 10px;
  border: none;
  background: var(--rejectText);
  color: var(--white);
  width: 100%;
  &:hover {
    background: var(--rejectText) !important;
    color: var(--white) !important;
  }
}
