.setting {
  &_head {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-flow: wrap;

    h2 {
      font-size: var(--headingFour);
      font-weight: 500;
      color: var(--mainBlack);
      margin-bottom: 48px;
    }
  }
  &_inputs {
    margin-bottom: 48px;
    .inputLayout {
      width: 100%;
    }
  }
  &_Btn {
    max-width: 168px;
    width: 100%;
  }
}
