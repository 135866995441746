.viewDetails {
  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    p {
      color: var(--sidebarText);
      font-size: var(--largeParaGraph16);
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 12px;
      span {
        color: var(--mainBlack);
      }
    }

  }
  &_cardSec {
    display: flex;
    align-items: end;
    justify-content: space-between;
    margin: 20px 0;
    gap: 20px;
    &_card {
      max-width: 379px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .viewDetailsText {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      &_name {
        color: var(--sidebarText);
        font-size: var(--largeParaGraph16);
        font-weight: 400;
      }
    }
    &_btn {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}
