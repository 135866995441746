.completedRequests {
  &_top {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    justify-content: space-between;
    gap: 20px;
    .customDropdown {
      max-width: 178px;
    }
    .PendingRequestsBtn {
      display: flex;
      align-items: center;
      gap: 10px;
      .ant-btn {
        width: 115px;
      }
    }
  }
  .UserTable {
    margin-top: 20px;
  }
}
