.dashLayout {
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  display: flex;
  &_content {
    flex: 1;
    &_body {
      height: calc(100vh - 162px);
      overflow-y: auto;
      padding: 40px 40px 20px 40px;
      background: var(--bodyBg);
      @media (max-width: 767px) {
        padding: 15px;
      }
    }
  }
}
