.paymentDetails {
  &_top {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    margin-top: 18px;
    &_filters {
      display: flex;
      align-items: center;
      gap: 16px;
      @media screen and (max-width: 1200px) {
        flex-flow: wrap;
        gap: 30px;
      }
      .currencyPayout {
        position: relative;
        .currencyLabel {
          position: absolute;
          top: -26px;
          color: var(--sidebarText);
          font-size: var(--mediumParaGraph);
        }
      }
      .inputLayout {
        width: 100%;
        input {
          height: 40px;
          border-radius: 10px;
        }
        .label {
          padding: 0;
          white-space: nowrap;
          position: absolute;
          top: -23px;
          color: var(--sidebarText);
          font-size: var(--mediumParaGraph);
        }
      }
      .greenBtn {
        height: 40px;
        border-radius: 10px;
        max-width: 103px;
      }
    }
  }
  &_asset {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
    margin-bottom: 20px;
    .customDropdown {
      max-width: 178px;
      width: 100%;
    }
  }
}
