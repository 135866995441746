.sidebar {
  min-width: 252px;
  width: 252px;
  background: #fcfcfc;
  transition: all 0.4s ease;
  z-index: 9;
  transform: translateX(0);
  opacity: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  &.close {
    margin: 0;
    width: 100px;
    @media screen and (max-width: 767px) {
      width: 70px;
    }
  }
  &.open {
    position: absolute;
    z-index: 3;
  }
  &_logo {
    padding: 25px 56px 24px 28px;
    border-bottom: 2px solid var(--bodyBg);
    border-right: 2px solid var(--bodyBg);
    .smallLogo {
      display: none;
    }
  }
  &_bottom {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 22px 0 28px;
    @media (max-width: 991px) {
      padding: 0 22px;
    }
  }
  &_link {
    &_items {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 10px;
      &_icon {
        padding: 8px;
        border-radius: 6px;
        background: var(--bodyBg);
        display: flex;
        align-items: center;
      }
      a {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--sidebarText);
        font-size: var(--largeParaGraph16);
        font-style: normal;
        font-weight: 400;
        padding: 10px 0;
        width: 100%;
        cursor: pointer;
        text-decoration: none;
        @media (max-width: 767px) {
          // padding: 11px 10px;
          font-size: 14px;
          img {
            height: 16px;
          }
        }
        svg {
          fill: var(--sidebarText);
        }
        &:hover,
        &.active {
          color: var(--mainBlack);
          .sidebar_link_items_icon {
            background: var(--greenbg);

            path {
              fill: var(--mainBlack);
            }
          }
        }
      }
    }
  }
}

.btnToggle {
  border: 0;
  background-color: transparent;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 35px;
  transition: 0.3s all;
  position: relative;
  height: 24px;
  transition: all 0.3s all;

  &:hover {
    cursor: pointer;
    span {
      background-color: var(--pinkColor);
    }
  }
  span {
    background-color: var(--white);
    height: 5px;
    border-radius: 10px;
    width: 35px;
    position: absolute;
    left: 0;
    z-index: 1;
    transition: all 0.3s ease;
    transform: rotate(180deg);
    @media (max-width: 767px) {
      width: 30px;
      height: 4px;
    }
    &:nth-child(2) {
      top: 12px;
      @media (max-width: 767px) {
        top: 10px;
      }
    }
    &:first-child {
      top: 0;
    }
    &:last-child {
      top: 24px;
      @media (max-width: 767px) {
        top: 20px;
      }
    }
  }
}
.closed {
  span {
    transition: all 0.3s ease;
    &:nth-child(2) {
      width: 0;
      opacity: 0;
    }
    &:first-child {
      transform: rotate(225deg);
      width: 40px;
      top: 10px;
      @media (max-width: 767px) {
        transform: rotate(220deg);
        width: 34px;
      }
    }
    &:last-child {
      transform: rotate(140deg);
      width: 40px;
      top: 10px;
      @media (max-width: 767px) {
        transform: rotate(140deg);
        width: 34px;
      }
    }
  }
}
.sidebarResponsive {
  width: 115px;
  min-width: 115px;
  @media screen and (max-width: 767px) {
    width: 74px;
    min-width: 55px;
  }

  .sidebar_logo {
    padding: 25px 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px) {
      padding: 12px;
    }
    .smallLogo {
      display: block;
    }
    .logo {
      display: none;
    }
  }

  .sidebar_link_items {
    a {
      justify-content: center;
    }
  }
}
