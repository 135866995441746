.transactionDetails {
  &_top {
    max-width: 178px;
    width: 100%;
    margin-bottom: 24px;
  }
}
.ongoingCompleted {
  &_filter {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    flex-flow: wrap;
    margin-bottom: 20px;
    &_btns {
      display: flex;
      align-items: center;
      flex-flow: wrap;
      gap: 16px;
    }
    .customDropdown {
      max-width: 178px;
    }
  }
}
