.no-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: 200px;
}

.no-data-message {
  font-size: 1.2rem;
  color: #666;
  margin-top: 10px;
}

.no-data-icon {
  img {
    max-width: 150px;
    width: 100%;
  }
}
