.dashboard {
  &_graph {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    @media (max-width: 991px) {
      flex-direction: column;
    }
    &_section {
      margin-top: 70px;
      img {
        max-width: 742px;
        width: 100%;
      }
    }
    > div {
      width: 100%;
      background: var(--mainWhite);
      padding: 26px;
      border-radius: 20px;
      @media screen and (max-width: 590px) {
        padding: 15px;
      }
      h3 {
        font-size: var(--largeParaGraph16);
        font-weight: 500;
        color: var(--mainBlack);
      }
    }
  }
}
.merchantsCard {
  flex-wrap: wrap;

  &_items {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    position: relative;
    flex: 1 1 calc(25% - 20px);
    max-width: calc(25% - 20px);

    @media (max-width: 1699px) {
      flex: 1 1 calc(50% - 20px);
      max-width: 380px;
    }
    @media (max-width: 991px) {
      max-width: 100%;
      flex: 100%;
    }

    .commonMonthBtn {
      position: absolute;
      top: 15px;
      right: 15px;
      @media screen and (max-width: 590px) {
        top: 7px;
        right: 15px;
      }
    }
  }

  &_icon {
    background: var(--bodyBg);
    padding: 15px;
    border-radius: 5px;
  }
}
.merchantsCard_detail {
  h3 {
    font-size: var(--largeParaGraph16) !important;
    font-weight: 500;
    color: var(--mainBlack);
    word-break: break-all;
  }
  p {
    font-size: var(--largeParaGraph16);
    font-weight: 400;
    color: var(--sidebarText);
  }
}
.graphCard {
  margin-top: 30px;
  display: flex;
  align-items: center;

  gap: 20px;
  @media (max-width: 1699px) {
    flex-wrap: wrap;
  }
  &_item {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-right: 40px;
    border-right: 1px solid var(--borderColor);
    &:last-child {
      border-right: none;
    }
  }
}
.colorDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: wrap;
  margin-top: 20px;
  gap: 10px;
  max-width: 343px;
  width: 100%;

  p {
    font-size: var(--mediumParaGraph);
    color: var(--mainBlac);
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  &_yellow,
  &_green,
  &_red {
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 2px;
    margin-right: 10px;
  }

  &_yellow {
    background: #eac131;
  }
  &_green {
    background: #5cd100;
  }
  &_red {
    background: #e32424;
  }
}
.merchantPayment,
.revenueChart {
  &_head {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    gap: 20px;
    justify-content: space-between;
  }
}
.recharts-wrapper {
  max-width: 700px !important;
  width: 100% !important;
}
.recharts-legend-wrapper {
  max-width: 273px !important;
  width: 100% !important;
}

// .cardfixedHeight{
//   height: 140px;
//   @media(max-width:1599px){
//     height: unset;
//   };
// }
