.login {
  &_link {
    text-align: right;
    margin-top: 17px;
    a {
      color: var(--mainBlack);
      font-size: var(--largeParaGraph16);
      font-weight: 600;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &_btn {
    margin-top: 40px;
  }
}
.congratsModal,
.alertModal {
  max-width: 507px !important;

  &:nth-child(1) {
    padding: 0 15px;
  }

  .ant-modal-content {
    text-align: center;
    background-color: #fff;

    h3 {
      font-size: var(--heading28);
      font-weight: 700;
      margin: 0;
    }

    p {
      color: var(--sidebarText);
      font-size: var(--largeParaGraph);
      font-weight: 400;
      margin-bottom: 50px;
    }
  }
}

.alertModal {
  max-width: 507px !important;

  &_footer {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
.common-modal {
  width: 100% !important;
  color: var(--whiteFFF);

  .ant-modal-close {
    color: var(--whiteFFF);
    &:hover {
      color: var(--pinkColor);
    }
  }

  .modal-content {
    padding-top: 15px;
  }
}
.ant-modal-mask {
  background-color: rgba(74, 64, 74, 0.45) !important;
}
.error-message {
  color: red;
  font-size: 14px;
}
