.merchantDetails {
  .ant-tabs-nav-more {
    display: none;
  }
  .greenBtn {
    max-width: 172px;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding: 4px 26px;
    span {
      color: var(--mainBlack) !important;
      font-size: var(--mediumParaGraph) !important;
      font-weight: 500;
    }
  }
}
.addNewMerchant {
  max-width: 1350px;
  width: 100%;
  &_btn {
    margin-top: 69px;
    max-width: 213px;
    width: 100%;
  }
}
.merchantView {
  h2 {
    color: var(--mainBlack);
    font-size: var(--largeParaGraph16);
    font-weight: 500;
  }
  p {
    font-size: var(--largeParaGraph16);
    font-weight: 400;
    color: var(--sidebarText);
    white-space: nowrap;
  }
  h4 {
    font-size: var(--largeParaGraph16);
    font-weight: 400;
    color: var(--mainBlack);
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &_top {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    @media (max-width: 1599px) {
      flex-wrap: wrap;
    }
    .commonCardBG {
      flex-basis: 33.3%;

      @media (max-width: 1599px) {
        flex-basis: calc(50% - 24px);
        max-width: 513px;
      }
      @media (max-width: 991px) {
        max-width: 100%;
        flex-basis: 100%;
      }
    }

    &_detail {
      width: 100%;
      @media (min-width: 1699px) {
        min-width: 513px;
      }
      @media (max-width: 1599px) {
        max-width: 100%;
      }

      > div {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &_middle {
    display: flex;
    gap: 24px;
    margin: 24px 0;
    flex-flow: wrap;
    @media (max-width: 1199px) {
      flex-direction: column;
    }
    > div {
      width: 100%;
      height: 100%;
      flex: 1 1 calc(50% - 12px);
      @media (max-width: 767px) {
        flex-basis: 100%;
      }
    }
    &_container {
      max-width: 513px;
      width: 100%;
      @media (max-width: 1199px) {
        max-width: 100%;
      }
    }
    &_head,
    &_addresses {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
    }
    &_addresses {
      margin-top: 24px;
    }
  }
  .earnedCard {
    display: flex;
    align-items: center;
    gap: 16px;
    // max-width: 513px;
    width: 100%;
    position: relative;
    @media (max-width: 767px) {
      max-width: 100%;
    }
    .commonMonthBtn {
      position: absolute;
      top: 15px;
      right: 15px;
    }
    h3 {
      font-size: var(--largeParaGraph16);
      color: var(--mainBlack);
      font-weight: 500;
      // margin-bottom: 16px;
      word-break: break-all;
    }
    &_dropDown {
      display: flex;
      align-items: center;
      gap: 10px;
      img {
        height: 24px;
        width: 24px;
      }
      .ant-dropdown-trigger {
        padding: 0 !important;
        background: transparent !important;
        border: none !important;
      }
    }

    &_icon {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--bodyBg);
      border-radius: 6px;
    }
  }
}
.KYBDocument {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 18px;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    justify-content: center;
  }
  &_card {
    padding: 24px 16px 16px 24px;
    background: linear-gradient(101.5deg, #222d2d -18.04%, #606b74 89.68%);
    border-radius: 10px;
    min-width: 200px;
    width: 100%;
    height: 122px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    flex: 1 1 calc(33% - 10px);
    max-width: calc(33% - 10px);

    @media (max-width: 1599px) {
      flex: 1 1 calc(50% - 10px);
      max-width: 322px;
    }

    @media (max-width: 991px) {
      flex: 1 1 100%;
      padding: 16px;
      // max-width: 100%;
    }

    h5 {
      color: var(--white);
      font-size: var(--largeParaGraph);
      font-weight: 400;
    }
    h6 {
      color: var(--greenbg);
      font-size: var(--largeParaGraph16);
      font-weight: 400;
      text-align: right;
    }
  }
}
.enabledAssets {
  max-width: 513px;
  width: 100%;
  @media (max-width: 1199px) {
    max-width: 100%;
  }
  &_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 16px;
    gap: 10px;
    > div {
      flex: 1 1 calc(33% - 20px);
      max-width: calc(33% - 20px);
      @media (max-width: 991px) {
        flex: 1 1 calc(50% - 20px);
        max-width: calc(50% - 20px);
      }
      @media (max-width: 991px) {
        flex: 1 1 100%;
        max-width: 100%;
      }
    }
  }
}
.merchantLogo {
  max-width: 703px;
  img {
    max-width: 654px;
    width: 100%;
  }
}
