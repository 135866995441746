.radioCustom {
  display: flex;
  flex-flow: wrap;
  .ant-radio-checked {
    .ant-radio-inner {
      background-color: var(--mainBlack) !important;
    }
  }
  .ant-radio .ant-radio-inner {
    border-color: var(--sidebarText);
    background-color: var(--mainWhite);
    &:hover {
      border-color: var(--sidebarText) !important;
    }
  }

  .ant-radio-inner::after {
    background-color: var(--greenbg);
  }
  .ant-radio-inner {
    background-color: transparent;
  }
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner {
    border-color: var(--sidebarText) !important;
  }
  .radioText {
    font-size: 14px;
    color: var(--mainBlack);
    white-space: nowrap;
  }
}
